<template>
  <div>
  <TosListVuetify :entityName="entityName" :headers="headers" :prepareItemFunction="prepareItem">
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>
          <v-col cols="4">
            <v-text-field label="Codice" v-model="slotProps.data.codice" />
          </v-col>
          <v-col cols="4">
            <v-select label="Tipo mezzo" v-model.number="slotProps.data.tipoMezzoMovimentazioneId"
              :items="tipiMezzoMovimentazione" item-text="label" item-value="id"  />
          </v-col>
       </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="6"> 
            <v-select label="Tipo mezzo" v-model.number="slotProps.data.tipoMezzoMovimentazioneId"
              :items="tipiMezzoMovimentazione" item-text="label" item-value="id" 
               :rules="[rules.required]" :error-messages="slotProps.errors.tipoMezzoMovimentazione"
               />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
             <v-text-field label="codice" v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
          </v-col>
          <v-col cols="4"> 
             <v-text-field label="descrizione" v-model="slotProps.data.descrizione" :error-messages="slotProps.errors.descrizione" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-body-1 text-uppercase font-weight-regular py-2 my-2 panelheader">
                Binari lavorazione mezzo, per parco
              </v-card-title>
              <v-card-text>
                <v-container v-for="park in parks" :key="park.id">
                  <v-row>
                      <v-col class="text-h6 font-weight-bold">
                        {{park.codice}}
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col v-for="rail in park.rails" :key="rail.id">                    
                          <v-checkbox v-model="selectedRails" 
                            :value="rail.id"
                            :label="`B. ${rail.numero}`"      
                            @click="manageSelectedRails(slotProps.data)"          
                          />                    
                      </v-col>
                  </v-row>
                </v-container>        
              </v-card-text>
            </v-card>    
          </v-col>
        </v-row>      
      </div>
    </template>
  </TosListVuetify>
</div>

</template>


<script>
import TosListVuetify from '../../components/anagrafiche/TosListVuetify';
import RailTosService from '../../service/tos/RailTosService';
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "MezziMovimentazione",
  data() {
    return {
      entityName: "mezziMovimentazione",
      tipiMezzoMovimentazione: null,
      selectedRails: [],
      headers: [
        { text: "", value: "action" },
        { text: "Tipo mezzo", value: "tipoMezzoMovimentazione", link: "true" },
        { text: "Codice", value: "codice" },
        { text: "Descrizione", value: "descrizione" },
      ],
    };
  },
  service: null,
  created() {
    this.service = new RailTosService(this.entityName);
  },
  async mounted() {
    await this.fetchData();   
  },
  methods: {
    async fetchData() {
      const res = await this.$api.railData.getParchiBinari();
      this.parks = res.data;

      // console.log("1 - parks", this.parks);
      
      this.parks.forEach(async (park) => {
        park.rails = await this.$api.railData.getRailwayData(park.codice);
        // console.log("binari", park.codice, park.rails);
      });

      // console.log("2 - parks", this.parks);

      setTimeout(() => {
        this.service.getShortList("tipiMezzoMovimentazione").then(data => {
          this.tipiMezzoMovimentazione = [ ...data.data];
        });
      }, 1000);
          
    },
    manageSelectedRails(vehicle) { // mettendo direttamente selectedRails del mezzo come model della checkbox aggiunge solo ultimo selezionato (boh, TODO)
      vehicle.selectedRails = this.selectedRails;
      // console.log("***vehicle.selectedRails", vehicle.selectedRails);
      // console.log("***selectedRails", this.selectedRails);
    },
    prepareItem(item) {
      this.selectedRails = [];
      if (item.id && item.binariMezzo && item.binariMezzo.length>0) {
        item.binariMezzo.forEach(binarioMezzo => {
          this.selectedRails.push(binarioMezzo.binarioId);
        });        
      } 
      // console.log("+++selectedRails", this.selectedRails);
      item.selectedRails = this.selectedRails;
    },
  },
  components: {
    TosListVuetify: TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>
